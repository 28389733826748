import React from 'react'
import Dashboard from '../components/dashboard/Dashboard'
import Sidebar from '../components/sidebar/Sidebar';
import { Layout } from 'antd';
const { Content } = Layout;

function DashboardPage() {
    return (
        <div>
            <Layout>
                <div className='fixed'>
                    <Sidebar />
                </div>
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <Dashboard />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default DashboardPage