import React, { useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createAdmin, editAdmin } from '../../../api/AdminRequest';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  // wrapperCol: {
  //   xs: {
  //     span: 24,
  //   },
  //   sm: {
  //     span: 16,
  //   },
  // },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function CreateAdmin({ edit }) {

  const navigate = useNavigate();

  const { state } = useLocation();
  // console.log(state?.drawerData, '************************');
  const [adminData] = useState(edit ? state.drawerData : '')
  const initial = [{ type: null, limit: "" }]

  const [featuresList, setFeaturesList] = useState(edit ? state?.drawerData?.features : initial);
  // const [featureType, setFeatureType] = useState(state ? state?.productType : null);
  const [name, setName] = useState('');
  const defaultItems = [
    {
      name: "Business Card",
      value: "businessCard"
    },
    {
      name: "Review Card",
      value: "reviewCard"
    },
    {
      name: "Feedback Card",
      value: "feedbackCard"
    },
    {
      name: "Contact Card",
      value: "contactCard"
    },
  ]
  const [items, setItems] = useState(defaultItems);

  const [phone, setPhone] = useState(edit ? '' + state.drawerData.phone : '971')
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const onFinish = async (values) => {

    // console.log('Received values of phone: ', phone);
    values.phone = phone
    values.features = featuresList
    console.log('Received values of form: ', values);
    try {
      const { data } = edit ? await editAdmin(adminData._id, values) : await createAdmin(values)
      console.log(data);
      if (data.success) {
        toast.success('New Admin Created', {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });
      } else if (data.update) {
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });

      }
      setTimeout(() => {
        navigate('/view-admins')
      }, 3000)
    } catch (error) {
      console.log(error.message);
      toast(error.message)
    }
  };

  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/


  let addFormFields = () => {
    setFeaturesList([...featuresList, { type: null, limit: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...featuresList];
    newFormValues.splice(i, 1);
    setFeaturesList(newFormValues);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    // Validation: Check if the item already exists or if the name is empty
    if (!name || items.some(item => item?.value === name)) {
      // Handle validation error (you can show a message, etc.)

      return;
    }
    setItems([...items, { name: name, value: name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };


  const removeItem = (removedItem) => {
    // Filter out the removed item
    const updatedItems = items.filter((item) => item.value !== removedItem.value);
    setItems(updatedItems);
  };

  const getOptionLabel = (selectedItem) => {
    if (defaultItems.some((item) => item.value === selectedItem.value)) {
      // For default items, exclude the delete button
      return <span>{selectedItem.name}</span>;
    }

    // For dynamically added items, include the delete button
    return (
      <div className='flex justify-between items-center'>
        <p>{selectedItem.name}</p>

        <CloseOutlined
          onClick={() => removeItem(selectedItem)}
          style={{ cursor: 'pointer', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.25)' }}
        />
      </div>
    );
  };

  // const handleChangeCardType = (value) => {
  //   console.log(`selected ${value}`);
  //   // if (value !== 'BusniessQR' || value !== 'reviewQR') {
  //   //     setFilterMaterial(null)
  //   // }
  //   // setFileList([])
  //   // setproductType(value)
  // };

  // const handleChangeCardType = async (name, index, value) => {
  //   console.log(index, 'index');
  //   console.log(`selected ${value}`);
  //   setFeaturesList(prevValues => {
  //     let updatedValues = [...prevValues];

  //     // Update the specified property in the array at the given index
  //     updatedValues[index] = {
  //       ...updatedValues[index],
  //       [name]: value,
  //     };

  //     return updatedValues;
  //   })
  // };
  const handleChangeCardType = (field, index, value) => {
    const updatedFeaturesList = [...featuresList];
    updatedFeaturesList[index][field] = value;
    setFeaturesList(updatedFeaturesList);
  };

  // console.log(featuresList, '??????');

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={2}>{edit ? 'Edit Admin' : 'Create Admin'}</Title>
        </Col>
      </Row>

      <div className='flex h-screen justify-center mt-14'>

        {/* <div> */}
        {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            name: adminData.name,
            email: adminData.email,
            // cardLimit: adminData.cardLimit,
            expiryDate: adminData.expiryDate
          }}
          style={{
            // maxWidth: 600,
            width: 600
          }}
          scrollToFirstError
        >
          <div className='grid grid-cols-2 gap-2 '>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>Name </label>
              <Form.Item
                name="name"
                // label="Name "
                className='mb-2'
                tooltip="What do you want others to call you?"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                    whitespace: true,
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>E-mail</label>
              <Form.Item
                name="email"
                // label="E-mail"
                className='mb-2'
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>Phone Number</label>
              <PhoneInput country={"eg"} enableSearch={true} value={phone} onChange={(phone) => setPhone(phone)} inputStyle={{ width: '300px', height: '10px' }} />
            </div>

            {/* <div className='grid grid-cols-2 gap-2 '> */}
            {/* <div>
              <label htmlFor="" className='text-xl font-semibold'>Card Limit</label>
              <Form.Item
                name="cardLimit"
                // label="E-mail"
                className='mb-2'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Card Limit!',
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div> */}
            <div>
              <label htmlFor="" className='text-xl font-semibold'>Expiry Days</label>
              <Form.Item
                name="expiryDate"
                // label="E-mail"
                className='mb-2'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Expiry Days!',
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div>
          </div>
          <div className='my-3'>
            {/* <label htmlFor="" className='text-xl font-semibold'>Features</label> */}
            <h1 className='text-xl font-semibold'>Features</h1>
            {featuresList?.map((element, index) => (
              <div className="grid gap-5 md:grid-cols-2" key={index}>
                <div>
                  <label
                    for="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                  >
                    card Type
                  </label>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    value={element?.type}
                    size='large'
                    placeholder="Select Card Type"
                    onChange={(value) => handleChangeCardType('type', index, value)}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px',
                          }}
                        >
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          {(!name || items.some(item => item.value === name)) ?
                            <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                            :
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                              Add item
                            </Button>
                          }
                        </Space>
                      </>
                    )}
                  //   options={items.map((item) => ({
                  //     label: getOptionLabel(item),
                  //     value: item.value,
                  //   }))}
                  // />
                  >
                    {items?.map((item) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        disabled={featuresList.some((feature) => feature.type === item.value)}
                      >
                        {getOptionLabel(item)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="flex gap-5 w-full">
                  <div className="w-full">
                    {element?.type === 'feedbackCard' ?
                      <div className='grid grid-cols-2 gap-2 '>
                        <div>
                          <label
                            for="first_name"
                            className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                          >
                           Manger Limit
                          </label>
                          <input
                            type="text"
                            name="websiteUrl"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="service Manger Limit"
                            value={element?.serviceMangerLimit || ""}
                            onChange={(e) => handleChangeCardType('serviceMangerLimit', index, e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            for="first_name"
                            className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                          >
                           Employee Limit
                          </label>
                          <input
                            type="text"
                            name="websiteUrl"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Employee Limit"
                            value={element?.employeeLimit || ""}
                            onChange={(e) => handleChangeCardType('employeeLimit', index, e.target.value)}
                          />
                        </div>
                      </div>
                      :
                      <>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                        >
                          Card Limit
                        </label>
                        <input
                          type="text"
                          name="websiteUrl"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          placeholder="Card Limit"
                          value={element?.limit || ""}
                          onChange={(e) => handleChangeCardType('limit', index, e.target.value)}
                        />
                      </>
                    }

                  </div>
                  {index ? (
                    <button
                      type="button"
                      className="mt-8"
                      onClick={() => removeFormFields(index)}
                    >
                      <Tooltip placement="top" title="Remove">
                        <MinusCircleOutlined
                          style={{ fontSize: "20px", color: "red" }}
                        />
                      </Tooltip>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-8"
                      onClick={() => addFormFields()}
                    >
                      <Tooltip placement="top" title="Add More">
                        <PlusCircleOutlined
                          style={{ fontSize: "20px", color: "green" }}
                        />
                      </Tooltip>
                    </button>
                  )}

                </div>
              </div>
            ))}
          </div>

          {edit ? "" :
            <>
              <div className='grid grid-cols-2 gap-2 '>
                <div>
                  <label htmlFor="" className='text-xl font-semibold'>Password</label>
                  <Form.Item
                    name="password"
                    //   label="Password"
                    className='mb-2'
                    rules={[

                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        pattern: passRegex,
                        message: 'password should contain Minimum 8 characters, at least one number, one uppercase and one lowercase letter and one special character!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password className='py-2' />
                  </Form.Item>

                </div>
                <div>
                  <label htmlFor="" className='text-xl font-semibold'>Confirm Password</label>
                  <Form.Item
                    name="confirm"
                    // label="Confirm Password"
                    className='mb-2'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >

                    <Input.Password className='py-2' />
                  </Form.Item>
                </div>
              </div>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  I have read the <Link href="">agreement</Link>
                </Checkbox>
              </Form.Item>
            </>
          }
          <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
            <Button className='bg-blue-800 w-32 mt-2 ' type="primary" htmlType="submit">
              {edit ? 'Save' : ' Register'}
            </Button>
          </Form.Item>
        </Form>
        {/* </div> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateAdmin